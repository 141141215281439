<template>
  <main class="recupery-password">
    <section class="content-login">
      <div class="content-login-div">
        <div class="content-login-logo">
          <img class="img-fluid" src="@/assets/img/home/logo-eurest.png" alt="logo eurest" title="logo eurest" />
        </div>
        <form ref="form" @submit.prevent="handleSubmit" class="content-login-form">
          <!-- <div class="content-input">
            <label>Cód. Empresa</label>
            <input v-model="unit" type="text" name="unit" required />
          </div> -->

          <div v-if="!this.$route.params.unit" class="content-input">
            <label>Cód. Empresa </label>
            <input id="recover_unit" v-model="unit" type="text" name="unit" required />
          </div>
          <div v-else>
            <input type="hidden" name="unit" :value="unit" />
          </div>

          <div class="content-input">
            <label>Cód. Utilizador</label>
            <input id="recover_custom_code" v-model="custom_code" type="text" name="custom_code" required />
          </div>
          <div class="content-input" v-if="obfuscated.email && obfuscated.mobile">
            <label>Selecione o Email ou Telemóvel</label>
            <select class="custom-select" name="recover_type" required>
              <option v-if="obfuscated.email && obfuscated.email !== '****'" value="recover_email">
                {{ obfuscated.email }}
              </option>
              <option v-if="obfuscated.mobile && obfuscated.mobile !== '****'" value="recover_mobile">
                {{ obfuscated.mobile }}
              </option>
            </select>
          </div>
          <!-- <div class="content-input">
            <label class="pb-2">Nº Telemóvel</label>
           
            <vue-tel-input
              class="py-3"
              @validate="validateInput"
              v-model="mobile"
              mode="international"
              :inputOptions="options"
              defaultCountry="PT"
              :validCharactersOnly="true"
            ></vue-tel-input>
            <input type="hidden" name="mobile_ddi" :value="mobile_ddi" />
            <input type="hidden" name="mobile_raw" :value="mobile_raw" />
          </div> -->
          <!-- <div @click="saveFormDataInStore()">
            <div class="content-input text-center text">
              <p class="text-warning">Receber código por email</p>
            </div>
          </div> -->
          <button type="submit" class="content-login-form-btn" :disabled="btnDisabled" :style="{
            backgroundColor: btnDisabled ? '#ccc' : '',
            opacity: btnDisabled ? 0.5 : 1,
            cursor: btnDisabled ? 'not-allowed' : 'pointer',
          }">
            Recuperar
          </button>
        </form>
        <!-- Return to login page -->
        <div class="pt-3">
          <a class="btn-return" href="/login">Voltar</a>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import User from "@/models/User";

import { mapActions } from "vuex";

export default {
  data() {
    return {
      btnDisabled: true,
      // mobile: null,
      // mobile_ddi: null,
      // mobile_raw: null,
      unit: "",
      custom_code: "",
      obfuscated: {
        email: null,
        mobile: null,
      },
      // options: {
      //   placeholder: "Digite um telemóvel",
      //   maxlength: 25,
      //   showDialCode: true,
      //   required: true,
      //   autocomplete: "on",
      //   name: "mobile",
      // },
    };
  },
  watch: {},
  methods: {
    ...mapActions("User", ["login"]),
    ...mapActions("App", ["setAlert", "setError", "loading"]),
    debounce(callback, wait) {
      let timeout;
      return (...args) => {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(context, args), wait);
      };
    },

    async getRecoverData() {
      this.loading(true);
      let formData = {
        unit: this.unit || this.$route.params.unit,
        custom_code: this.custom_code,
      };
      try {
        const response = await User.getRecoverInfo(formData);
        console.log(response);
        if (!response.data.error) {
          this.obfuscated.email = response.data.email;
          this.obfuscated.mobile = response.data.mobile;
          this.btnDisabled = false;
        } else {
          this.setAlert(response.data.message);
          this.obfuscated.email = null;
          this.obfuscated.mobile = null;
          this.btnDisabled = true;
        }
        this.loading(false);
      } catch (error) {
        this.setError(error);
        this.obfuscated.email = null;
        this.obfuscated.mobile = null;
      }
    },
    async handleSubmit() {
      let formData = this.$root.getAllData(this.$refs.form);

      if (this.$refs.form.checkValidity()) {
        this.loading(true);
        try {
          const response = await User.recoverPass(formData);
          console.log("recover pass", response);
          this.loading(false);
          this.setAlert(response.data.message);
          if (!this.$route.params.unit) {
            this.$router.push({ name: "login" });
          } else {
            this.$router.push({
              name: "login",
              params: { unit: this.$route.params.unit },
            });
          }
        } catch (error) {
          this.setError(error);
        }
      } else {
        this.$refs.form.reportValidity();
      }
    },
  },
  mounted() {
    sessionStorage.removeItem("token");
    if (this.$route.name == "recover-pass") {
      //add only at two inputs, unit and custom_code
      this.$refs.form.addEventListener(
        "input",
        this.debounce(() => {
          if (this.unit?.length > 0 && this.custom_code?.length > 0) {
            this.getRecoverData();
          }
        }, 6500)
      );
    }

    this.unit = this.$route.params.unit;
  },
};
</script>
<style scoped>
.btn-return {
  display: flex;
  justify-content: center;
  font-size: 13px;
  font-family: "Font Regular";
  color: #ffffff !important;
  transition: 500ms;
  text-decoration: none;
}
</style>
